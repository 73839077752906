<script lang="ts" setup>
import { isServer } from '@/lib/client'
import { markRaw, ref, watch } from 'vue'

const props = defineProps<{
  component: any
}>()

const comp = ref()

async function load() {
  if (!props.component) {
    comp.value = undefined
    return
  }

  const module = await props.component()
  const { default: component, ..._frontmatter } = module

  // const meta = parseFrontmatter(frontmatter, '') // slug is not used, therefore src=''
  // const tags = meta.tags ? arraySorted(meta.tags) : []

  // const date = meta.day
  //   ? dayToDate(meta.day).toLocaleDateString(meta.lang ?? 'en', {
  //     year: 'numeric',
  //     month: 'long',
  //     day: 'numeric',
  //   })
  //   : undefined

  // const info = {
  //   component,
  //   frontmatter,
  //   meta,
  //   tags,
  //   date,
  // }

  // log('info', info)

  comp.value = markRaw(component)
}

watch(() => props.component, load)

if (isServer)
  await load()
else
  void load()
</script>

<template>
  <template v-if="comp">
    <component :is="comp" />
  </template>
</template>
