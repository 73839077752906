<script lang="ts" setup>
import AppContent from '@/components/app-content.vue'
import AppYoutube from '@/components/app-youtube.vue'
import { useHead } from '@unhead/vue'
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { jsonStringifySorted } from 'zeed'
import { gotoAlias } from '../../src/goto-alias'
import { useHeadDescription, useHeadTitle } from '../../src/lib/use-app-head-holtwick'
import youtube from './_assets/youtube.png'

import './app-homepage.styl'

const { t, locale } = useI18n()

const {
  baseUrl,
} = globalThis.siteConfig

const json = [
  {
    '@context': 'http://www.schema.org',
    '@type': 'WebSite',
    'name': 'PDFify',
    'alternateName': 'PDFify - Software for macOS',
    'url': `${baseUrl}`,
  },
  {
    '@context': 'http://schema.org/',
    '@type': 'SoftwareApplication',
    'name': 'PDFify',
    'applicationCategory': 'Productivity',
    'operatingSystem': 'macOS',
    'downloadUrl': `${baseUrl}/latest`,
    'image': `${baseUrl}/icon.png`,
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '4.78',
      'ratingCount': '102',
    },
    'offers': {
      '@type': 'Offer',
      'priceCurrency': 'EUR',
      'price': '29',
    },
  },
  {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    'url': `${baseUrl}`,
    'name': 'PDFify',
    'contactPoint': {
      '@type': 'ContactPoint',
      'email': 'support@pdfify.app',
      'url': `${baseUrl}/${locale.value}/support`,
      'contactType': 'customer support',
    },
  },
]

watch(locale, () => {
  useHeadTitle(t('homepage.pageTitle'))
  useHeadDescription(t('site.description'))
}, { immediate: true })

useHead({
  meta: [
    { property: 'og:video', content: 'https://youtu.be/CIm6OFC166s' },
  ],

  // https://unhead.unjs.io/usage/composables/use-script#background
  script: json.map(data => ({
    type: 'application/ld+json',
    textContent: jsonStringifySorted(data),
  })),
})

const mas = gotoAlias['/goto/appstore-pdfify']
</script>

<template>
  <main class="app-homepage">
    <div class="hero">
      <div class="grid-container">
        <div class="content">
          <div class="content-text">
            <div class="headlines">
              <h1 class="clip">
                {{ $t("homepage.title") }}
              </h1>
              <p class="slogan">
                {{ $t("homepage.slogan") }}
              </p>
            </div>
            <div class="actions">
              <a class="button" href="/latest">{{ $t("homepage.download") }}</a>
              <!-- <router-link :to="`/${$i18n.locale}/help`" >
                {{ $t("homepage.buttonMore") }}
              </router-link> -->
              <!-- <router-link :to="`/${$i18n.locale}/buy`" class="button">
                Buy now
              </router-link> -->
            </div>
            <div class="configuration">
              {{ $t("homepage.requirements") }}
              <br>
              {{ $t("homepage.appstore") }}
              <a :href="mas" target="_blank">Mac App Store</a>.
            </div>
          </div>
          <ClientOnly>
            <div class="content-show">
              <AppYoutube
                src="https://www.youtube.com/embed/CIm6OFC166s"
                :src-preview="youtube"
              />
            </div>
          </ClientOnly>
        </div>
      </div>
    </div>
    <div class="app-content prose">
      <Suspense>
        <AppContent :component="$route.meta.comp" />
      </Suspense>
    </div>
  </main>
</template>
